.carousel {
	position: relative;
	overflow: hidden;
	// z-index: 0;
	.carousel-button {
		border: 1px solid #111;
		width: 55px;
		height: 55px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		font-size: 40px;
		transition: all 0.2s ease-in-out;

		&:hover {
			background: #111;
			color: white;
		}
		&:active {
			font-size: 35px;
		}
		&.next {
			position: fixed;
			top: 50%;
			right: 5%;
			&.disabled {
				background: #e8e8e8;
				color: white;
				border: 1px solid #e8e8e8;
				&:active {
					font-size: 40px;
				}
			}
		}
		&.prev {
			position: fixed;
			top: 50%;
			left: 5%;
		}
		svg {
		}
	}
}

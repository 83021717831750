.logo {
	align-items: center;
	background-color: #111;
	border-radius: 50%;
	display: flex;
	height: 50px;
	width: 50px;
	justify-content: center;
	cursor: pointer;
	&.mobile-logo {
		width: 35px;
		height: 35px;
		margin-left: 15px;
	}
}

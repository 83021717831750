.top-bar {
	display: flex;
	align-items: flex-end;
	padding-top: 10px;
	justify-content: space-between;
	height: 90px;
	margin: 0 10.4%;
	overflow-x: hidden;
	&.s-margin {
		margin: 0 2% 0 1%;
	}

	.top-bar-right {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.language {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 14px;
			.devider {
				height: 20px;
				border-left: 2px solid #111111;
			}
			.lang {
				margin: 0 10px;
				cursor: pointer;
				transition: all 0.2s ease-in-out;
				&.selected {
					font-weight: 500;
					color: white;
					background: #000;
					padding: 2px 5px;
					border-radius: 5px;
				}
			}
		}
		.menu-wrapper {
			display: flex;
			margin: 0 20px 0 20px;
			cursor: pointer;
		}
		.user-profile {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 35px;
			height: 35px;
			color: white;
			background: #000;
			font-weight: 600;
			border-radius: 50%;
			margin: 0 20px;
			cursor: pointer;
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.top-bar {
		height: 60px;
		margin: 0 2%;
		.top-bar-right {
			.user-profile {
				margin: 0 20px;
				width: 30px;
				height: 30px;
			}
		}
	}
}

.modal {
	height: 100svh;
	width: 20%;
	position: absolute;
	background-color: white;
	color: black;
	padding: 25px;
	z-index: 1;
	transition: all 0.2s ease-in-out;
	box-shadow: 2px 4px 10px rgba(25, 25, 27, 0.5);
	overflow: auto;
	&.design {
		width: 22.5%;
		.content {
			display: flex;
		}
	}
	&.expanded {
		width: 65%;
	}
	&.displayed {
		top: 0px;
		left: 0px;
	}
	&.hidden {
		top: 0px;
		left: -500px;
	}
	.top {
		.close-icon {
			display: flex;
			justify-content: flex-end;
			margin-right: 20px;
			.icon {
				color: #000;
				font-size: 25px;
				border: 1px solid #000;
				width: 20px;
				height: 20px;
				padding: 5px;
				border-radius: 50%;
				cursor: pointer;
				transition: all 0.2s ease-in-out;
				&:hover {
					color: white;
					background: #000;
					border: 1px solid #000;
				}
				&:active {
				}
			}
		}
		.information {
			margin-top: 25px;
			p {
				font-size: 18px;
				font-weight: 600;
				line-height: 32px;
			}
		}
	}
	.content {
		margin-top: 20px;
		.colour-option {
			display: flex;
			align-items: center;
			padding: 15px 10px;
			cursor: pointer;
			border-radius: 5px;
			border: 1px solid white;
			transition: all 0.2s ease-in-out;
			&.selected {
				border: 1px solid #161616;
			}
			.color-text {
				font-size: 16px;
				font-weight: bold;
			}
			.color-background {
				width: 30px;
				height: 30px;
				border-radius: 50%;
				margin-right: 10px;
			}
		}
		.designs-wrapper {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			.design-style {
				width: 165px;
				height: 165px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				margin: 10px 10px 5px 0;
				border-radius: 5px;
				cursor: pointer;
			}
		}
		.modal-extras {
			margin-left: 20px;
			.selected-design-style {
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				margin: 10px 10px 5px 0;
				border-radius: 5px;
				width: 800px;
				height: 660px;
			}
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
}

//Tablets
@media only screen and (min-width: 768px) and (max-width: 1099px) {
	.modal {
		width: 30%;
		&.design {
			width: 30%;
		}
		&.expanded {
			width: 99%;
		}
		.top {
			.information {
				// text-align: center;
				p {
					font-size: 20px;
				}
			}
		}
		.content {
			margin-top: 5px;
			.colour-option {
				padding: 15px 10px;
			}
			.designs-wrapper {
				.design-style {
					width: 100px;
					height: 100px;
				}
			}
			.modal-extras {
				margin-left: 15px;
				.selected-design-style {
					width: 530px;
					height: 480px;
				}
			}
		}
	}
}

// Desktops & laptops
@media only screen and (min-width: 1100px) and (max-width: 1450px) {
	.modal {
		&.design {
			width: 23%;
			.content {
				display: flex;
			}
		}
		&.expanded {
			width: 80%;
		}
		.top {
			.close-icon {
				margin: 0;
			}
			.information {
				h3 {
					margin-left: 10px;
					font-size: 20px;
				}
			}
		}
		.content {
			margin-top: 5px;
			.colour-option {
				padding: 15px 10px;
			}
			.designs-wrapper {
				.design-style {
					width: 100px;
					height: 100px;
				}
			}
			.modal-extras {
				margin-left: 15px;
				.selected-design-style {
					width: 620px;
					height: 480px;
				}
			}
		}
	}
}

.results {
	width: 100%;
	display: grid;
	grid-template-columns: 20% 75%;
	gap: 2rem;
	overflow-x: hidden;
	box-sizing: border-box;
	margin-top: 50px;
	.result-gallery {
		display: flex;
		flex-direction: column;
		align-items: center;
		.contact-section {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			margin-bottom: 10px;
			.navigate {
				display: flex;
				align-items: center;
				cursor: pointer;
				span {
					text-decoration: underline;
					font-weight: 600;
				}
			}
		}
		.images {
			display: flex;
			flex-direction: column;
			.image-container {
				position: relative;
				.image-wrapper {
					display: flex;
					justify-content: center;
					.result-image {
						width: 100%;
						height: 550px;
						cursor: pointer;
						border-radius: 5px;
						margin: 0;
						-webkit-transition: all 0.2s ease-out;
						-moz-transition: all 0.2s ease-out;
						-o-transition: all 0.2s ease-out;
						transition: all 0.2s ease-out;
						&:hover {
							opacity: 0.8;
							// padding: 5px;
							// border-radius: 15px;
						}
					}
				}
				.image-icons {
					position: absolute;
					top: 15px;
					right: 5px;
					display: flex;
					.icon {
						background: white;
						font-size: 15px;
						border-radius: 5px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #fff;
						width: 26px;
						height: 26px;
						cursor: pointer;
						&.download-icon {
							margin: 0px 10px;
						}
					}
				}
			}
			.result-control {
				display: flex;
				justify-content: space-between;
				// margin-bottom: 30px;
				.actions {
					display: flex;
					position: relative;
					.share-modal {
						position: absolute;
						// top: 100%;
						width: 305px;
						border-radius: 10px;
						box-shadow: 2px 4px 10px rgb(0 0 0 / 25%);
						padding: 10px;
						z-index: 200;
						margin-top: 10px;
						background-color: white;
						transition: transform 0.2s;
						&.displayed {
							transform: translateX(200%);
							opacity: 1;
						}
						&.hidden {
							transform: translateX(500%);
							opacity: 1;
						}

						.share-modal-top {
							display: flex;
							justify-content: space-between;
							padding: 10px;
							.modal-top-info {
								h4 {
									font-size: 14px;
									color: #111;
								}
								.top-info-text {
									font-size: 12px;
									color: #585757;
								}
							}
							.close-icon {
								font-size: 20px;
								cursor: pointer;
							}
						}

						hr {
							border-top: 1px solid #dadada;
							margin-top: 5px;
						}
						.copy-button {
							display: flex;
							justify-content: center;
							align-items: center;
							background-color: rgb(233, 233, 233);
							margin: 10px 0 0px 0;
							padding: 10px;
							cursor: pointer;
							border-radius: 25px;
							-webkit-transition: all 0.3s ease-out;
							-moz-transition: all 0.3s ease-out;
							-o-transition: all 0.3s ease-out;
							transition: all 0.3s ease-out;
							&:hover {
								background-color: rgb(204, 204, 204);
							}
							.copy-icon {
								font-size: 22px;
							}
							.button-text {
								font-weight: bold;
								margin: 0px 10px;
								font-size: 14px;
							}
						}
						.image-link {
							font-size: 11px;
						}
					}
					.copy-success {
						position: absolute;
						display: flex;
						flex-direction: row;
						justify-content: center;
						background-color: white;
						height: auto;
						width: 305px;
						padding: 10px;
						box-shadow: 2px 4px 10px rgb(0 0 0 / 25%);
						border-radius: 10px;
						transition: transform 0.2s;
						z-index: 200;
						&.success {
							transform: translateX(200%);
							opacity: 1;
						}
						&.hidden {
							transform: translateX(500%);
							opacity: 1;
						}

						.success-icon {
							color: #207433;
							font-size: 25px;
							margin-right: 10px;
						}
					}
				}
			}
		}
		.history-gallery {
			display: flex;
			// justify-content: center;
			background-color: rgb(233, 233, 233);
			margin-top: 10px;
			overflow-x: scroll;
			border-radius: 5px;
			max-width: 825px;
			width: 100%;
			padding: 5px 15px;
			height: 160px;
			.slider {
				height: 100%;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				.slide {
					cursor: pointer;
					transition: transform 0.2s;
					margin-right: 25px;
					padding-bottom: 5px;
					display: flex;
					flex-direction: column;
					&.selected {
						border-bottom: 3px solid #111;
					}
					&:last-child {
						margin-right: 0;
					}
					&:hover {
						-ms-transform: scale(1.1); /* IE 9 */
						-webkit-transform: scale(1.1); /* Safari 3-8 */
						transform: scale(1.1);
					}
					.slide-image {
						width: 120px;
						height: 90px;
						border-radius: 7px;
						margin-bottom: 7px;
					}
				}
			}
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.results {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding: 10px;
		overflow: hidden;
		margin-top: 0;
		.result-gallery {
			display: flex;
			flex-direction: column;
			margin-top: 20px;
			height: 100%;
			.contact-section {
				margin-bottom: 10px;
				padding: 0 10px 0 5px;
				// flex-direction: column;
				// align-items: normal;
				h4,
				p {
					font-size: 14px;
				}
				.navigate {
					margin-top: 5px;
					span {
						font-size: 14px;
					}
				}
			}
			.images {
				margin-bottom: 10px;
				.result-control {
					justify-content: flex-end;
					margin: 0 0 10px 0;
					.actions {
						.share-modal {
							&.displayed {
								transform: translateX(-110%);
								opacity: 1;
							}
							&.hidden {
								transform: translateX(200%);
								opacity: 1;
							}
						}
						.copy-success {
							&.success {
								transform: translate(-110%, 120px);
								opacity: 1;
							}
							&.hidden {
								transform: translateX(200%);
								opacity: 1;
							}
						}
					}
				}
				.image-container {
					position: relative;
					.image-wrapper {
						.result-image {
							width: 100%;
							height: 100%;
						}
					}
					.image-icons {
						position: absolute;
						top: 10px;
						right: 0px;
						display: flex;
						.icon {
							background: white;
							font-size: 14px;
							border-radius: 5px;
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: #fff;
							width: 24px;
							height: 24px;
							&.download-icon {
								margin-left: 10px;
							}
						}
					}
				}
			}
			.history-gallery {
				background-color: rgb(233, 233, 233);
				overflow-y: scroll;
				display: flex;
				border-radius: 5px;
				max-width: 375px;
				width: 100%;
				height: 100px;
				margin-top: 10px;
				padding: 5px;
				.slider {
					height: 100%;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 15px 10px;
					.slide {
						cursor: pointer;
						transition: transform 0.2s;
						margin-right: 15px;
						padding-bottom: 5px;
						display: flex;
						flex-direction: column;
						&:last-child {
							margin-right: 0;
						}
						&:hover {
							-ms-transform: scale(1.1); /* IE 9 */
							-webkit-transform: scale(1.1); /* Safari 3-8 */
							transform: scale(1.1);
							border-bottom: 3px solid #111;
						}
						.slide-image {
							width: 70px;
							height: 50px;
							border-radius: 7px;
							margin-bottom: 7px;
						}
					}
				}
			}
		}
	}
}

//Tablets
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.results {
		grid-template-columns: 25% 75%;
		overflow: hidden;

		.result-gallery {
			align-items: normal;
			.images {
				.image-container {
					.image-wrapper {
						span {
							display: flex !important;
							justify-content: flex-start;
						}
						.result-image {
							width: 90%;
							height: 400px;
						}
					}
					.image-icons {
						right: 15%;
					}
				}
				.result-control {
					display: flex;
					justify-content: space-between;
					// margin-bottom: 30px;
					.actions {
						display: flex;
						position: relative;
						.share-modal {
							&.displayed {
								transform: translateX(50%);
							}
						}
						.copy-success {
							&.success {
								transform: translateX(50%);
							}
						}
					}
				}
			}
			.history-gallery {
				width: 90%;
			}
		}
	}
}

// Desktops & laptops
@media only screen and (min-width: 1024px) and (max-width: 1450px) {
	.results {
		// background-color: red;
		grid-template-columns: 20% 75%;
		.result-gallery {
			.images {
				// align-items: center;
				.image-container {
					.image-wrapper {
						span {
							display: flex !important;
							justify-content: center;
						}
						.result-image {
							width: auto;
							// height: 550px;
						}
					}
					// .image-icons {
					// 	right: 5px;
					// 	top: 15px;
					// }
				}
			}
			.history-gallery {
				// width: 90%;
				padding: 5px 15px;
				height: 160px;
			}
		}
	}
}

@media only screen and (min-width: 1451px) and (max-width: 1650px) {
	.results {
		// background: #000;
		// grid-template-columns: 20% 75%;
		.result-gallery {
			.image-wrapper {
				.result-image {
					width: 100%;
					height: 550px;
				}
			}
		}
		.side-gallery {
			.sides {
				width: 100%;
				.side-gallery_image-wrapper {
					display: flex;
					justify-content: center;
					margin: 15px 0;
					font-size: 14px;
					.gallery-image {
						width: 115px;
						height: 70px;
						margin: 0 10px;
					}
				}
			}
		}
	}
}

.applications {
	margin: 0 10.4%;
	height: calc(100svh - 90px);
	display: flex;
	padding: 100px 0;
	.app-title {
		width: 50%;
		h1 {
			font-size: 45px;
		}
		p {
			padding-right: 100px;
			font-size: 16px;
		}
	}
	.app-cards {
		width: 50%;
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
	}
}
@media (min-width: 270px) and (max-width: 767px) {
	.applications {
		margin: 0 5%;
		flex-direction: column;
		align-items: center;
		.app-title {
			width: 100%;
			border-bottom: 1px solid #c8c8c8;
			padding-bottom: 15px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			h1 {
				font-size: 24px;
			}
			p {
				padding: 0;
				// word-break: break-all;
			}
		}
		.app-cards {
			margin-top: 40px;
			width: 100%;
			grid-template-columns: 1fr;
		}
	}
}

.failure {
	height: calc(100% - 90px);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;
	position: absolute;
	top: 90px;
	left: 0%;
	z-index: 100;
	.info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 50px;
		text-align: center;
		.navigate {
			display: flex;
			align-items: center;
			justify-content: center;
			align-self: center;
			width: 75%;
			border: 1px solid #111;
			height: 50px;
			border-radius: 25px;
			font-weight: 600;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			background: #000;
			color: white;
			margin-top: 30px;
			svg {
				margin-right: 10px;
			}
			&:active {
				scale: 0.95;
			}
		}
	}
}

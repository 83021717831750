.landing {
	display: flex;
	justify-content: center;
	height: calc(100svh - 160px);
	margin: 0px 10.4%;
	padding-top: 8%;
	.landing-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.landing-wrapper {
			.title {
				font-size: 70px;
				font-weight: 900;
				text-align: center;
				margin-bottom: 20px;
				.gradient {
					padding: 0px 15px;
					background: #111;
					color: white;
				}
			}
			.text {
				font-size: 18px;
				text-align: left;
				line-height: 26px;
				max-width: 740px;
				width: 100%;
				margin: 0 auto;
				font-weight: 500;
			}
			.landing-button-wrapper {
				margin: 30px auto 50px auto;
				width: 100%;
				display: flex;
				justify-content: center;
				.landing-button {
					width: 70%;
					background: none;
					border: none;
					padding: 12px 20px;
					line-height: 24px;
					background-color: black;
					color: #fff;
					font-size: 16px;
					font-weight: bolder;
					border-radius: 25px;
					cursor: pointer;
					transition: all 0.2s ease;

					&:hover {
						background-color: rgb(56, 56, 56);
					}
					&:active {
						font-size: 14px;
					}
				}
			}
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.landing {
		// height: 100%;
		flex-direction: column;
		justify-content: flex-start;
		margin: 50px 0;
		padding-top: 15%;
		// justify-content: center;
		.landing-container {
			padding: 0 5%;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.landing-wrapper {
				padding-right: 0px;
				margin-right: 0px;
				.landing-button-wrapper {
					.landing-button {
						width: 100%;
					}
				}
				.title {
					font-size: 32px;
					margin-bottom: 15px;
					.gradient {
						padding: 5px 10px;
						background: #111;
						color: white;
					}
				}
				.text {
					font-size: 14px;
					text-align: left;
					line-height: 22px;
				}
			}
			.landing-button-wrapper {
				margin-top: 20px;
				.landing-button {
					width: 100%;
					margin-top: 0px;
					font-size: 14px;
					// padding: 10px 15px;
				}
			}
		}
	}
}

//Tablets
@media only screen and (min-width: 768px) and (max-width: 1099px) {
	.landing {
		margin: 70px 8%;
		padding-top: 8%;
		.landing-container {
			justify-content: space-between;
			.landing-wrapper {
				margin-right: 10px;
				.title {
					font-size: 45px;
					margin-bottom: 15px;
				}
				.text {
					font-size: 18px;
					line-height: 26px;
				}
			}
			.landing-button-wrapper {
				.landing-button {
					font-size: 18px;
					padding: 15px;
				}
			}
		}
	}
}

// Desktops & laptops
@media only screen and (min-width: 1100px) and (max-width: 1450px) {
	.landing {
		.landing-container {
			.landing-wrapper {
				margin-right: 10px;
				.title {
					font-size: 55px;
					margin-bottom: 15px;
				}
				.text {
					font-size: 16px;
				}
			}
			.upcoming {
				.text {
					line-height: 26px;
					margin: 0px auto;
				}
			}
			.landing-button-wrapper {
				.landing-button {
					font-size: 16px;
					padding: 10px 15px;
				}
			}
		}
	}
}

@media only screen and (min-width: 1451px) and (max-width: 1650px) {
	.landing {
		.landing-container {
			.landing-wrapper {
				margin-right: 10px;
				.title {
					font-size: 60px;
					margin-bottom: 10px;
				}
				.text {
					font-size: 16px;
					line-height: 24px;
					.text-link {
						text-decoration: underline;
						cursor: pointer;
						font-weight: 500;
					}
				}
			}
		}
	}
}

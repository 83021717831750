.prompt {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;

	.prompt-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		.prompt-content {
			padding: 20px;
			background: #fff;
			border-radius: 5px;
			box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.4);
			max-width: 500px;
			line-height: 24px;
			.content-title {
				font-size: 16px;
				font-weight: 700;
				margin: 5px 0;
			}
			.content-text {
				font-size: 14px;
			}
			.content-question {
				padding-top: 15px;
				font-weight: 600;
				font-size: 16px;
				margin-top: 15px;
			}
			.prompt-actions {
				display: flex;
				justify-content: space-between;
				margin-top: 15px;
				.action {
					text-align: center;
					width: 48%;
					border: 1px solid #111;
					padding: 6px;
					border-radius: 25px;
					font-weight: 600;
					cursor: pointer;
					transition: all 0.2s ease-in-out;
					&.accept {
					}
					&.reject {
						color: white;
						background: #111;
					}
					&:active {
						scale: 0.95;
					}
				}
			}
		}
	}
}

.contact {
	margin: 0 10.4%;
	overflow-x: hidden;
	margin-top: 80px;
	display: flex;
	justify-content: space-between;
	padding-bottom: 30px;
	.contact-info {
		width: 45%;
		display: flex;
		flex-direction: column;
		padding-right: 50px;

		p {
			font-size: 18px;
		}
	}
	.contact-data {
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.design-path {
			margin: 10px 0 0 0;
			p {
				font-size: 14px;
				font-weight: 600;
				margin-bottom: 10px;
			}
			img {
				height: 100px;
				border-radius: 5px;
				margin-bottom: 10px;
			}
		}
		.textarea-wrapper {
			display: flex;
			flex-direction: column;
			// width: 30%;
			label {
				font-size: 14px;
				color: #424242;
				margin-bottom: 5px;
			}
			textarea {
				margin-top: 20px;
				resize: none;
				height: 120px;
				font-size: 16px;
				outline: none;
				padding: 10px;
				&::placeholder {
					font-size: 14px;
				}
			}
		}
		.submit-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 40px;
			font-weight: bold;
			height: 50px;
			border-radius: 30px;
			border: none;

			&.enabled {
				background: #000;
				color: white;
				font-size: 16px;
				transition: all 0.2s ease-in-out;
				cursor: pointer;
			}
			&:hover {
				background-color: rgb(56, 56, 56);
			}
			&.disabled {
				background: #e8e8e8;
				color: #929292;
				pointer-events: none;
			}
			&:active {
				scale: 0.95;
			}
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.contact {
		margin: 80px 5% 0 5%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		.contact-info {
			width: 100%;
			padding-right: 0;
			h1 {
				font-size: 24px;
				margin-bottom: 10px;
			}
			h2 {
				font-size: 18px;
			}
		}
		.contact-data {
			// .design-path {
			// 	margin: 10px 0 0 0;
			// 	p {
			// 		font-size: 14px;
			// 		font-weight: 600;
			// 		margin-bottom: 10px;
			// 	}
			// 	img {
			// 		height: 100px;
			// 		border-radius: 5px;
			// 		margin-bottom: 10px;
			// 	}
			// }
			width: 100%;
			margin-top: 25px;
		}
	}
}

.button-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	background: #000;
	color: white;
	height: 60px;
	font-size: 16px;
	font-weight: bold;
	border-radius: 30px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	width: 100%;
	border: none;
	&:hover {
		background-color: rgb(56, 56, 56);
	}
	&.disabled {
		background: #e8e8e8;
		color: #929292;
		pointer-events: none;
	}
	&:active {
		font-size: 15px;
	}
}

.choice {
	border: 1px solid #c8c8c8;
	padding: 25px;
	height: 85px;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	&.selected {
		background: #000;
		color: #fff;
	}
	&.disabled {
		pointer-events: none;
		background: #e8e8e8;
		color: #929292;
	}

	&:hover {
		border: 2px solid #111;
	}
	&:active {
		font-size: 15px;
		margin: 0 5px;
	}
	.choice-wrapper {
		text-align: center;
	}
}
@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.choice {
		&:hover {
			background-color: inherit;
			color: inherit;
		}
		&.selected {
			background: #000;
			color: #fff;
		}
	}
}

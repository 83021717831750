.question {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 50px;
	padding-bottom: 50px;

	.choices-wrappepr,
	.info {
		width: 50%;
	}

	.info {
		h1 {
			font-size: 40px;
		}
		p {
			font-size: 16px;
		}
	}
	.choices-wrappepr {
		margin-right: 15px;
		.choices {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 1rem;
		}
		.modal-label {
			margin-top: 20px;
			cursor: pointer;
			span {
				text-decoration: underline;
				line-height: 24px;
				color: #424242;
				&:hover {
					color: #161616;
				}
			}
		}
	}
}

.interior-design-app,
.exterior-design-app {
	margin: 0 10.4%;
	overflow-x: hidden;
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
	.interior-design-app,
	.exterior-design-app {
		margin: 0 5%;
		.carousel {
			.question {
				flex-direction: column;
				justify-content: space-around;
				margin-top: 60px;
				.info {
					width: 100%;
					border-bottom: 1px solid #c8c8c8;
					padding-bottom: 15px;
					text-align: center;
					h1 {
						font-size: 28px;
						margin-bottom: 5px;
					}
					p {
						font-size: 16px;
					}
				}
				.choices-wrappepr {
					margin-top: 40px;
					width: 100%;
					margin-right: 0;
					.choices {
						.choice {
							padding: 15px;
							height: 75px;
							font-size: 16px;
						}
					}
				}
			}
			.carousel-button {
				width: 60px;
				height: 60px;
				font-size: 35px;
				&:active {
					font-size: 23px;
				}
				&.next {
					top: 90%;
				}
				&.prev {
					top: 90%;
					&.invisible {
						visibility: hidden;
					}
				}
			}
		}
	}
}

@media (min-width: 270px) and (max-width: 767px) {
	.interior-design-app,
	.exterior-design-app {
		margin: 0 5%;
		.navigation {
			width: auto;
			.nav-item {
				font-size: 12px;
			}
		}
		.carousel {
			.question {
				flex-direction: column;
				justify-content: space-around;
				margin-top: 60px;
				.info {
					width: 100%;
					border-bottom: 1px solid #c8c8c8;
					padding-bottom: 15px;
					text-align: center;
					h1 {
						font-size: 24px;
						margin-bottom: 5px;
					}
					p {
						font-size: 14px;
					}
				}
				.choices-wrappepr {
					margin-top: 40px;
					width: 100%;
					margin-right: 0;
					.choices {
						.choice {
							padding: 15px;
							height: 75px;
							font-size: 14px;
						}
					}
				}
			}
			.mobile-buttons-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 20px;
				padding: 20px 0px;
				.carousel-button {
					width: 40px;
					height: 40px;
					font-size: 25px;
					&:active {
						font-size: 23px;
					}
					&.next {
						position: relative;
					}
					&.prev {
						position: relative;
						&.invisible {
							visibility: hidden;
						}
					}
				}
			}
		}
		.modal {
			height: 100svh;
			width: 100%;
			padding: 15px;
			&.design {
				width: 100%;
				.content {
					display: flex;
					justify-content: center;
				}
			}
			&.hidden {
				top: 0px;
				left: -800px;
			}
			.top {
				.close-icon {
					margin: 0;
				}
				.information {
					margin-top: 20px;
					h3 {
						text-align: center;
						font-size: 20px;
					}
				}
			}
			.content {
				margin-top: 10px;

				.colour-option {
					display: flex;
					align-items: center;
					padding: 15px 10px;
					cursor: pointer;
					border-radius: 5px;
					border: 1px solid white;
					transition: all 0.2s ease-in-out;
					&.selected {
						border: 1px solid #161616;
					}
					.color-text {
						font-size: 16px;
						font-weight: bold;
					}
					.color-background {
						width: 30px;
						height: 30px;
						border-radius: 50%;
						margin-right: 10px;
					}
				}
				.designs-wrapper {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					.design-style {
						width: 165px;
						height: 165px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						margin: 10px 10px 5px 0;
						border-radius: 5px;
						cursor: pointer;
					}
				}
				.modal-extras {
					display: none;
				}
			}
		}
	}
}

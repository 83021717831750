.error-wrapper {
	height: calc(100% - 90px);
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #fff;
	position: absolute;
	top: 90px;
	left: 0%;
	z-index: 100;
	.error-icon {
		margin-bottom: 20px;
	}
	.error-title {
		margin-bottom: 5px;
	}
	.link {
		font-weight: bold;
		cursor: pointer;
		text-decoration: underline;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.error-wrapper {
		.error-title {
			text-align: center;
		}
	}
}

.menu {
	width: 20%;
	height: 100%;
	position: absolute;
	transition: all 0.2s ease-in-out;
	background: #fff;
	padding: 20px;
	z-index: 2;
	box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.4);
	overflow: hidden;
	&.displayed {
		z-index: 1000;
		top: 0;
		left: 0;
	}
	&.hidden {
		top: 0px;
		left: -500px;
	}
	.menu-top {
		display: flex;
		justify-content: flex-end;
		padding-bottom: 20px;
		.close-menu {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			&:hover {
				border: 1px solid #111;
			}
		}
	}
	.menu-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		margin-top: 25px;
		.menu-item,
		.profile-items {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-left: 10px;
			margin-top: 1.25rem;
			font-weight: 600;
			font-size: 16px;
			cursor: pointer;
			a {
				text-decoration: none;
				color: #000;
			}
			span {
				margin: 0px 10px;
			}
			&:hover {
				span {
					border-bottom: 1px solid #585757;
				}
			}
		}
		.profile-items-wrapper {
			width: 100%;
			padding-top: 30px;
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.menu {
		width: 100%;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1099px) {
	.menu {
		width: 35%;
	}
}

@media only screen and (min-width: 1100px) and (max-width: 1450px) {
	.menu {
		width: 30%;
	}
}

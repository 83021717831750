.redirect {
	position: absolute;
	top: 90px;
	left: 0;
	width: 100%;
	background: white;
	display: flex;
	justify-content: space-evenly;
	padding: 100px 50px 0 50px;
	overflow: hidden;
	gap: 2rem;

	.redirect-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		h1 {
		}
		// &:first-child {
		// 	border-right: 2px solid red;
		// }
		p {
			margin-top: 5px;
			font-size: 18px;
			span {
				font-weight: bold;
				text-decoration: underline;
				cursor: pointer;
			}
		}
		.navigate {
			display: flex;
			align-items: center;
			margin-top: 40px;
			border: 1px solid #111;
			width: 20%;
			padding: 10px;
			justify-content: center;
			border-radius: 25px;
			cursor: pointer;
			background-color: #111;
			color: white;
			span {
				margin: 0px 10px;
				font-size: 18px;
				font-weight: bold;
			}
			transition: all 0.2s ease-in-out;
			&:hover {
				background-color: rgb(56, 56, 56);
			}
			&:active {
				scale: 0.95;
			}
		}
	}
}

@media (min-width: 270px) and (max-width: 767px) {
	.redirect {
		flex-direction: column;
		justify-content: space-between;
		padding: 50px 25px 0 25px;
		.redirect-section {
			align-items: normal;
			width: 100%;
			h1 {
				font-size: 21px;
			}
			p {
				margin-top: 5px;
				font-size: 16px;
			}
			.navigate {
				margin-top: 30px;
				width: 100%;
				span {
					font-size: 16px;
				}
			}
		}
	}
}

// //Tablets
// @media only screen and (min-width: 768px) and (max-width: 1099px) {
// 	.redirect {
// 		.redirect-section {
// 			width: 100%;
// 		}
// 	}
// }

// @media only screen and (min-width: 1100px) and (max-width: 1450px) {
// 	.redirect {
// 		.redirect-section {
// 			width: 100%;
// 		}
// 	}
// }
// @media only screen and (min-width: 1451px) and (max-width: 1650px) {
// 	.redirect {
// 		.redirect-section {
// 			width: 100%;
// 		}
// 	}
// }

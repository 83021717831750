.login-page {
	.login-wrapper {
		&.password {
			width: auto;
			margin: 50px 0 0 0;
		}
		.login-logo {
			margin-bottom: 30px;
		}
		.details {
			margin-bottom: 30px;
			.title {
				margin-bottom: 5px;
				text-align: center;
			}
			.text {
				text-align: center;
				font-weight: 500;
			}

			.steps {
				margin-top: 30px;
				// list-style: none;
				li {
					margin: 5px 0;
				}
			}
		}
	}
}

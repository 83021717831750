.contact-success {
	height: calc(100% - 90px);
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;
	position: absolute;
	top: 90px;
	left: 0%;
	z-index: 100;
	.info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 50px;
		text-align: center;
		.navigate {
			display: flex;
			align-items: center;
			justify-content: center;
			align-self: center;
			width: 75%;
			border: 1px solid #111;
			height: 50px;
			border-radius: 25px;
			font-weight: 600;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			background: #000;
			color: white;
			margin-top: 30px;
			svg {
				margin-right: 10px;
			}
			&:active {
				scale: 0.95;
			}
		}
	}
	.checkmark {
		width: 75px;
		height: 75px;
		border-radius: 50%;
		display: block;
		stroke-width: 2;
		stroke: #4bb71b;
		stroke-miterlimit: 10;
		box-shadow: inset 0px 0px 0px #4bb71b;
		animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
		position: relative;
		top: 5px;
		right: 5px;
		margin: 0 auto;
	}
	.checkmark__circle {
		stroke-dasharray: 166;
		stroke-dashoffset: 166;
		stroke-width: 2;
		stroke-miterlimit: 10;
		stroke: #4bb71b;
		fill: #fff;
		animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
	}

	.checkmark__check {
		transform-origin: 50% 50%;
		stroke-dasharray: 48;
		stroke-dashoffset: 48;
		animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
	}
}

@keyframes stroke {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes scale {
	0%,
	100% {
		transform: none;
	}

	50% {
		transform: scale3d(1.1, 1.1, 1);
	}
}

@keyframes fill {
	100% {
		box-shadow: inset 0px 0px 0px 30px #4bb71b;
	}
}

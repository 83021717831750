.login-page {
	height: calc(100svh - 90px);
	display: flex;
	justify-content: center;
	align-items: center;
	.login-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 500px;
		border: 1px solid #dcdcdc;
		padding: 50px 40px 40px 40px;
		background: #fff;
		border-radius: 15px;
		.login-logo {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 50px;
		}
		.login-form {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 10px;
			margin: 0 auto 10px auto;
			.form-group {
				.form-control {
					width: 100%;
					padding: 0.375rem 0.75rem;
					font-size: 1rem;
					font-weight: 400;
					line-height: 32px;
					color: #212529;
					background-color: #fff;
					background-clip: padding-box;
					border: 1px solid #ced4da;
					appearance: none;
					border-radius: 0.375rem;
					transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
				}
			}
			.errorValidation {
				text-align: left;
				color: rgb(237, 9, 85);
				margin-top: -10px;
			}
			.social-login {
				display: flex;
				justify-content: space-evenly;
				margin-top: 20px;
				.form-btn {
					width: 50px;
					height: 50px;
					font-size: 32px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					color: white;
					cursor: pointer;
					&.google-btn {
						background-color: #da4d3f;
						svg {
							color: white;
						}
					}
					&.fb-btn {
						background-color: #3d5897;
					}
				}
			}
			.forgot {
				display: flex;
				justify-content: flex-end;
				text-decoration: underline;
				font-size: 14px;
				cursor: pointer;
			}
			.login-btn {
				margin-top: 15px;
				background: none;
				border: none;
				padding: 15px;
				line-height: 22px;
				background-color: black;
				color: #fff;
				font-size: 16px;
				font-weight: bolder;
				border-radius: 25px;
				margin-bottom: 5px;
				cursor: pointer;
			}
			.sign-up-link {
				font-size: 16px;

				a {
					color: #161616;
					&:visited {
						color: #161616;
					}
				}
			}
			.center-div-line {
				width: 100%;
				display: flex;
				flex-direction: row;
				text-transform: uppercase;
				border: none;
				font-size: 12px;
				font-weight: 500;
				margin: 0;
				padding: 24px 0 0;
				&::after,
				&::before {
					content: '';
					border-bottom: 1px solid #c2c8d0;
					border-bottom: 1px solid var(black);
					flex: 1 0 auto;
					height: 0.8em;
					margin: 0;
				}
				span {
					text-align: center;
					flex: 0.2 0 auto;
					margin: 0;
				}
			}
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.login-page {
		height: auto;
		margin: 20px 0;
		.login-wrapper {
			border: none;
			padding: 50px 0px;
			.login-logo {
				display: none;
			}
			.details {
				margin-bottom: 30px;
				padding: 0 20px;
				.title {
					font-size: 18px;
				}
				.text {
					text-align: center;
					font-weight: 500;
					margin-bottom: 30px;
					font-size: 14px;
				}

				.steps {
					padding: 0 0 0 20px;
					font-size: 14px;
					li {
						margin: 5px 0;
					}
				}
			}
			.login-form {
				padding: 0 20px;
			}
		}
	}
}

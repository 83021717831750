.pricing {
	// width: 50%;
	height: 80%;
	margin: 40px 0 0 0;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: center;
	.pricing-container {
		width: 100%;
		height: 100%;
		position: relative;
		.pricing-top {
			color: white;
			background: #000;
			padding: 50px 0;
			height: 50%;
			h1 {
				text-align: center;
				margin-bottom: 10px;
				font-size: 40px;
			}
		}
		.pricing-bottom {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			position: absolute;
			width: 100%;
			top: 30%;
			.pricing-wrapper {
				width: 20%;
				padding: 10px 30px 30px 30px;
				background: white;
				box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
				border-radius: 10px;

				.price-item {
					.item-top {
						padding-top: 20px;
						.item-top-title {
							display: flex;
							justify-content: space-between;
							.target-title {
								font-size: 18px;
								font-weight: 500;
							}
							.popular-item {
								background: #e00751;
								color: white;
								font-weight: bold;
								font-size: 14px;
								padding: 5px;
								border-radius: 5px;
								transform: rotate(30deg);
							}
						}
						.item-top-price {
							// margin-bottom: 5px;
							.price {
								font-size: 28px;
								font-weight: bold;
								margin-right: 20px;
							}
						}
						.item-top-subtitle {
							font-size: 16px;
							font-weight: 500;
						}
					}
					.item-bottom {
						.item-top-details {
							ul {
								margin-top: 20px;
								list-style: none;
								li {
									padding: 2px 0;
								}
							}
						}
						.buy-item-btn {
							display: flex;
							justify-content: center;
							align-items: center;
							margin-top: 40px;
							background: #000;
							color: white;
							height: 50px;
							font-size: 16px;
							font-weight: bold;
							border-radius: 30px;
							transition: all 0.2s ease-in-out;
							cursor: pointer;
							width: 100%;
							border: none;
							&:hover {
								background-color: rgb(56, 56, 56);
							}
							&.disabled {
								background: #e8e8e8;
								color: #929292;
								pointer-events: none;
							}
							&:active {
								scale: 0.96;
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.pricing {
		margin: 50px 0;
		height: calc(100svh - 110px);
		width: 100%;
		overflow: hidden;
		.pricing-container {
			.pricing-top {
				padding: 30px 15px;
				h1 {
					font-size: 18px;
					margin-top: 30px;
				}
				.items-list {
					width: 90%;
					display: flex;
					justify-content: space-evenly;
					position: absolute;
					top: 33%;
					.list-item {
						border-bottom: 1px solid white;
						&.selected {
							border-bottom: none;
							font-weight: bold;
						}
					}
				}
			}

			.pricing-bottom {
				top: 40%;
				.pricing-wrapper {
					width: 90%;
					padding: 10px 25px 30px 25px;
					.price-item {
						.item-top {
							.item-top-title {
								.popular-item {
									font-size: 12px;
								}
							}
						}
					}
				}
			}
		}
	}
}

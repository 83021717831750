.checkbox-group {
	margin: 10px 0 20px 0;
	span {
		font-weight: 600;
		font-size: 14px;
	}
	.checkbox-list {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
		.checkbox-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			input {
				cursor: pointer;
				margin-right: 10px;
				width: 21px;
				height: 21px;
				accent-color: #111;
			}
			label {
				cursor: pointer;
				font-size: 14px;
			}
		}
	}
}

.image-theatre {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	.theatre-top {
		margin: 0 10.4%;
		height: 90px;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		.close-theatre {
			font-size: 22px;
			cursor: pointer;
			.icon {
				color: #000;
				font-size: 25px;
				border: 1px solid #000;
				width: 20px;
				height: 20px;
				padding: 5px;
				border-radius: 50%;
				cursor: pointer;
				transition: all 0.2s ease-in-out;
				&:hover {
					color: white;
					background: #000;
					border: 1px solid #000;
				}
			}
		}
	}
	.theatre-content {
		height: auto;
		display: flex;
		margin: 0 10.4%;
		justify-content: space-between;
		margin-top: 5%;
		.contact-section {
			width: 40%;
			display: flex;
			flex-direction: column;
			margin-right: 50px;
			h1 {
				margin-bottom: 10px;
				font-weight: 800;
			}
			h2 {
				font-size: 22px;
				font-weight: 700;
			}
			p {
				font-size: 18px;
				margin-top: 5px;
				text-align: left;
			}
			.navigate {
				display: flex;
				align-items: center;
				margin-top: 20px;
				border: 1px solid #111;
				width: 60%;
				padding: 10px;
				justify-content: center;
				border-radius: 25px;
				cursor: pointer;
				background-color: #111;
				color: white;
				span {
					margin: 0px 10px;
					font-size: 18px;
					font-weight: bold;
				}
				transition: all 0.2s ease-in-out;
				&:hover {
					background-color: rgb(56, 56, 56);
				}
				&:active {
					scale: 0.95;
				}
			}
		}
		.image-container {
			margin-bottom: 30px;
			.image-wrapper {
				position: relative;
				.image {
					border-radius: 5px;
					width: 100%;
				}
				.download-icon {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					background: white;
					right: 20px;
					top: 20px;
					width: 25px;
					height: 25px;
					border-radius: 5px;
					cursor: pointer;
					.icon {
						background: white;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #fff;
					}
				}
			}
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.image-theatre {
		.theatre-content {
			height: auto;
			display: flex;
			flex-direction: column;
			margin: 0;
			padding: 0 20px;
			justify-content: space-between;
			margin-top: 5%;
			.contact-section {
				width: 100%;
				display: flex;
				flex-direction: column;
				margin-bottom: 40px;
				h1 {
					margin-bottom: 5px;
					font-weight: 700;
					font-size: 24px;
				}
				h2 {
					font-size: 18px;
					font-weight: 700;
				}
				p {
					font-size: 16px;
					margin-top: 5px;
					text-align: left;
				}
				.navigate {
					width: 100%;
					padding: 8px;
					span {
						font-size: 16px;
					}
				}
			}
		}
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.image-theatre {
		.theatre-top {
			margin: 0 5%;
		}
		.theatre-content {
			height: auto;
			display: flex;
			flex-direction: column;
			margin: 0;
			padding: 0 20px;
			justify-content: space-between;
			margin-top: 5%;
			justify-content: center;
			.contact-section {
				margin: 0 5%;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin-bottom: 40px;
				h1 {
					margin-bottom: 5px;
					font-weight: 700;
					font-size: 28px;
				}
				h2 {
					font-size: 20px;
					font-weight: 700;
				}
				p {
					font-size: 18px;
					margin-top: 5px;
					text-align: left;
				}
				.navigate {
					width: 50%;
					padding: 8px;
					span {
						font-size: 16px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1450px) {
	.image-theatre {
		.theatre-top {
			margin: 0 5%;
		}
		.theatre-content {
			margin: 5%;

			.contact-section {
				width: 50%;
				h1 {
					margin-bottom: 10px;
					font-weight: 800;
					font-size: 24px;
				}
				h2 {
					font-size: 18px;
					font-weight: 700;
				}
				p {
					font-size: 16px;
					margin-top: 5px;
					text-align: left;
				}
				.navigate {
					// width: 100%;
				}
			}
			.image-container {
				// width: 100%;
			}
		}
	}
}

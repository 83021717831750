.select-wrapper {
	display: flex;
	flex-direction: column;
	margin: 20px 15px;
	.select-label {
		// font-weight: 500;
		margin-left: 5px;
		margin-bottom: 5px;
		font-size: 12px;
	}
	.input-wrapper {
		align-items: center;
		display: flex;
		position: relative;
		width: 100%;
		.select-input {
			padding: 10px 10px;
			height: 3rem;
			line-height: 1.5;
			font-size: 14px;
			line-height: 24px;
			border-radius: 5px;
			appearance: none;
			width: 100%;
		}
		.icon {
			bottom: 0.25rem;
			position: absolute;
			right: 0.5rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.generate-form {
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: rgb(233, 233, 233);
	overflow-y: auto;
	overflow-x: hidden;
	margin-bottom: 10px;
	.button-container {
		display: flex;
		justify-content: center;
		margin-top: 40px;
		.result-button-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 15px;
			border-radius: 25px;
			cursor: pointer;
			font-weight: bold;
			justify-self: center;
			font-size: 14px;
			transition: all 0.2s ease-in-out;
			width: 95%;
			&.enabled {
				background: #000;
				color: white;
			}
			&.disabled {
				background: #929292;
				color: #e8e8e8;
				pointer-events: none;
			}
			&:hover {
				background-color: rgb(63, 62, 62);
			}
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.generate-form {
		height: 100%;
		padding: 20px 5px;
		.form {
			.select-container {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 1rem;
				.select-wrapper {
					margin: 0;
				}
			}
			.button-container {
				margin-top: 30px;
			}
		}
	}
}

.profile-page {
	margin: 50px 8%;
	padding-bottom: 50px;
	.title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.profile-data {
		margin-top: 20px;
		.data-title {
			margin-bottom: 10px;
			font-size: 18px;
			font-weight: 700;
		}
		.user-info {
			margin-top: 10px;
			.info {
				margin-bottom: 10px;
				p {
					font-size: 16;
					font-weight: 600;
				}
				span {
					font-size: 16px;
				}
			}
		}
	}
	.profile-images {
		.images-title {
			margin-bottom: 10px;
			font-size: 18px;
			font-weight: 700;
		}
		.images-grid {
			display: grid;
			grid-template-columns: repeat(6, minmax(0, 1fr));
			grid-column-gap: 1.25rem;
			grid-row-gap: 1.25rem;
			grid-auto-flow: row dense;
			grid-auto-rows: 1fr;
			.img-wrapper {
				display: flex;
				// grid-column: span 2;
				// grid-row: span 2;
				grid-column: span 2;
				grid-row: span 4;
				position: relative;
				&.square {
					grid-column: span 2;
					grid-row: span 3;
				}
				.img {
					max-width: 100%;
					width: 100%;
					border-radius: 5px;
					// object-fit: cover;
					-webkit-transition: all 0.2s ease-out;
					-moz-transition: all 0.2s ease-out;
					-o-transition: all 0.2s ease-out;
					transition: all 0.2s ease-out;
					cursor: pointer;
					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.profile-page {
		.profile-data {
			margin-bottom: 40px;
			.user-info {
				.info {
					display: flex;
				}
			}
		}
		.profile-images {
			.images-grid {
				display: grid;
				grid-template-columns: repeat(6, minmax(0, 1fr));
				grid-column-gap: 0.75rem;
				grid-row-gap: 0.75rem;
				grid-auto-flow: row dense;
				grid-auto-rows: 1fr;
				.img-wrapper {
					display: flex;
					grid-column: span 3;
					grid-row: span 4;
					position: relative;
					&.square {
						grid-column: span 3;
						grid-row: span 3;
					}
					// .img {
					// 	max-width: 100%;
					// 	width: 100%;
					// 	border-radius: 5px;
					// 	// object-fit: cover;
					// 	-webkit-transition: all 0.2s ease-out;
					// 	-moz-transition: all 0.2s ease-out;
					// 	-o-transition: all 0.2s ease-out;
					// 	transition: all 0.2s ease-out;
					// 	cursor: pointer;
					// 	&:hover {
					// 		opacity: 0.8;
					// 	}
					// }
				}
			}
		}
	}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	.profile-page {
		.profile-data {
			margin-bottom: 40px;
			.user-info {
				.info {
					display: flex;
				}
			}
		}
		.profile-images {
			.images-grid {
				grid-column-gap: 1rem;
				grid-row-gap: 1rem;
				.img-wrapper {
					display: flex;
					grid-column: span 3;
					grid-row: span 4;
					position: relative;
					&.square {
						grid-column: span 3;
						grid-row: span 3;
					}
					// .img {
					// 	max-width: 100%;
					// 	width: 100%;
					// 	border-radius: 5px;
					// 	// object-fit: cover;
					// 	-webkit-transition: all 0.2s ease-out;
					// 	-moz-transition: all 0.2s ease-out;
					// 	-o-transition: all 0.2s ease-out;
					// 	transition: all 0.2s ease-out;
					// 	cursor: pointer;
					// 	&:hover {
					// 		opacity: 0.8;
					// 	}
					// }
				}
			}
		}
	}
}

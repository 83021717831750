.input {
	display: flex;
	flex-direction: column;
	// width: 30%;
	margin-bottom: 20px;
	input {
		border: none;
		border: 1px solid #c8c8c8;
		font-size: 16px;
		outline: none;
		padding: 10px;
		appearance: none;
		border-radius: 0.375rem;
		&[type='number'] {
			-moz-appearance: textfield;
		}
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&::placeholder {
			font-size: 14px;
		}
	}
	label {
		color: #929292;
		font-size: 12px;
		padding: 5px;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 550px) {
	.input {
		width: 100%;
	}
}

.card {
	background: #fff;
	border: 1px solid #c8c8c8;
	padding: 30px 20px;
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	.card-wrapper {
		.title {
			font-size: 16px;
			margin-bottom: 10px;
			border-bottom: 1px solid #c8c8c8;
			padding-bottom: 10px;
			text-align: center;
		}
		.text {
			text-align: left;
			line-height: 22px;
			padding: 10px 0;
		}
	}
	&:hover {
		background: #000;
		color: white;
		.title {
			border-bottom: 1px solid white;
		}
	}
}

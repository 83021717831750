.interior-ai-app {
	display: block;
	overflow-y: auto;
	width: 100%;
	overflow-x: hidden;

	.ai-app-container {
		height: 100svh;
		overflow: auto;
		.ai-app {
			height: 100%;
		}
	}
}

// .PhoneInputInput {
// 	border: 0 !important;
// 	width: 100%;
// 	padding: 0.375rem 0.75rem;
// 	font-size: 1rem;
// 	font-weight: 400;
// 	line-height: 32px;
// 	color: #212529;
// 	background-color: #fff;
// 	background-clip: padding-box;
// 	appearance: none;
// 	border-radius: 0.375rem;
// 	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }

.social-login button {
	border: 0px !important;
	background-color: #fff !important;
	box-shadow: none !important;
}

.social-login button div {
	margin-right: 0px;
	padding: 0px !important;
	border-radius: 0px !important;
}

.social-login button div {
	padding: 0 !important;
}

.social-login button div svg {
	display: none;
}

.social-login button div.google-btn {
	border-radius: 50% !important;
}

.social-login button div.google-btn svg {
	display: block;
}

// .PhoneInput {
// 	border: 1px solid #ced4da !important;
// 	padding-left: 10px;
// 	border-radius: 0.375rem !important;
// }

// .positionTitle {
// 	margin-top: -30px;
// 	margin-bottom: 10px;
// }

.navigation {
	display: flex;
	margin-top: 50px;
	width: 50%;
	justify-content: space-between;
	.nav-item {
		font-size: 14px;
		&.active {
			font-weight: bold;
		}
	}
}
@media (min-width: 270px) and (max-width: 767px) {
	.navigation {
		width: 100%;
		justify-content: space-around;
		flex-wrap: wrap;
		.nav-item {
			font-size: 14px;
			margin: 0px 10px 5px 0;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1099px) {
	.navigation {
		width: 100%;
	}
}

// Desktops & laptops
@media only screen and (min-width: 1100px) and (max-width: 1450px) {
	.navigation {
		width: 80%;
	}
}

@media only screen and (min-width: 1451px) and (max-width: 1650px) {
	.navigation {
		width: 70%;
	}
}
